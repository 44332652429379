import React from "react";
import { Typewriter } from "react-simple-typewriter";
import Button from "react-bootstrap/Button";
import { HashLink as Link } from "react-router-hash-link";

import "../styles/Hero.css";

export default function Hero() {
  return (
    <section className="hero" id="home">
      <h3>Welcome to</h3>
      <h1>Axelsen consulting</h1>
      <h4>My name is Jesper Axelsen and I'm a </h4>
      <h2>
        <Typewriter
          words={["software engineer", "dev-ops engineer", "cloud engineer"]}
          loop={0}
          cursor
          cursorStyle="_"
          cursorColor="#001858"
          typeSpeed={75}
          deleteSpeed={50}
          delaySpeed={1000}
        />
      </h2>
      <Link
        to="mailto:jesperbaxelsen@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button className="contact-button">contact me</Button>
      </Link>
    </section>
  );
}
