import React from "react";
import { SpinnerDotted } from "spinners-react";
import "../styles/LoadingSpinner.css";

export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <SpinnerDotted size={100} color="#001858" />
    </div>
  );
}

//   SpinnerCircular,SpinnerCircularFixed,  SpinnerCircularSplit,  SpinnerDiamond,  SpinnerDotted,  SpinnerInfinity,  SpinnerRomb,SpinnerRound, SpinnerRoundFilled, SpinnerRoundOutlined;
