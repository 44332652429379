import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../styles/VerticalEmailBar.css";

export default function VerticalEmailBar() {
  return (
    <div className="vertical-email-bar">
      <Link
        className="email-contact"
        to="mailto:jesperbaxelsen@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        jesperbaxelsen@gmail.com
      </Link>
      <>
        <div className="vertical-line">
          <div className="vr"></div>
        </div>
      </>
    </div>
  );
}
