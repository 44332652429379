import React from "react";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import "../styles/Certifications.css";
import CKSlogo from "../images/kubernetes-cks-color.svg";
import CKAlogo from "../images/kubernetes-cka-color.svg";

export default function Certifications() {
  return (
    <section className="certifications" id="certifications">
      <h3>certifications</h3>
      <div className="cert-content">
        <CardGroup>
          <Card>
            <Card.Img src={CKAlogo} />
            <Card.Body>
              <Card.Title>
                <h4>CKA</h4>
                <hr />
              </Card.Title>
              <Card.Text>
                The Certified Kubernetes Administrator (CKA) program was created
                by the Cloud Native Computing Foundation (CNCF), in
                collaboration with The Linux Foundation, to help develop the
                Kubernetes ecosystem.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            {" "}
            <Card.Img src={CKSlogo} />
            <Card.Body>
              <Card.Title>
                <h4>CKS (ongoing)</h4>
                <hr />
              </Card.Title>
              <Card.Text>
                The Certified Kubernetes Security Specialist (CKS) program was
                created by the Cloud Native Computing Foundation (CNCF), in
                collaboration with The Linux Foundation, to help develop the
                Kubernetes ecosystem.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>{" "}
    </section>
  );
}
