import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "../styles/Experience.css";
import "../styles/Accordion.css";

export default function Experience() {
  return (
    <section className="experience" id="experience">
      <h3>experience</h3>
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h2 className="accordion-heading">
              Freelance Software Engineer - Axelsen consulting
            </h2>
          </Accordion.Header>
          <Accordion.Body>
            {" "}
            <hr />
            <h4>
              September 22 - present. <small>Aarhus, DK.</small>
            </h4>{" "}
            <ul className="responsibilities-list">
              <li>
                Undertaken the development, architecture, and testing of inhouse
                projects
              </li>
              <li>Provided consultancy services to clients</li>
              <li>
                Project estimation, communication, and guidance to ensure
                optimal solutions for clients
              </li>
              <li>Responsible for the quality and delivery of own projects</li>
              <li>
                Internal mentoring, knowledge sharing, and collaboration
                opportunities
              </li>
            </ul>
            <ul className="tech-blocks">
              <li>Kubernetes</li>
              <li>Golang</li>
              <li>Helm</li>
              <li>Docker</li>
              <li>Ceph</li>
              <li>Flux</li>
              <li>ArgoCD</li>
              <li>Kafka</li>
              <li>Grafana</li>
              <li>Prometheus</li>
              <li>GitOps</li>
              <li>Terraform</li>
              <li>Ansible</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <h2> Senior Software Developer - IT Minds</h2>
          </Accordion.Header>
          <Accordion.Body>
            <hr />
            <h4>
              January 22 - September 22. <small>Aarhus, DK.</small>
            </h4>
            <ul className="responsibilities-list">
              <li>
                Undertaken the development, architecture, and testing of inhouse
                projects
              </li>
              <li>Provided consultancy services to clients</li>
              <li>
                Project estimation, communication, and guidance to ensure
                optimal solutions for clients
              </li>
              <li>Responsible for the quality and delivery of own projects</li>
              <li>
                Internal mentoring, knowledge sharing, and collaboration
                opportunities
              </li>
            </ul>
            <ul className="tech-blocks">
              <li>Kubernetes</li>
              <li>Golang</li>
              <li>Helm</li>
              <li>Docker</li>
              <li>Ceph</li>
              <li>Flux</li>
              <li>ArgoCD</li>
              <li>Kafka</li>
              <li>Grafana</li>
              <li>Prometheus</li>
              <li>GitOps</li>
              <li>Terraform</li>
              <li>Ansible</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <h2> Software Developer - IT Minds</h2>
          </Accordion.Header>
          <Accordion.Body>
            <hr />
            <h4>
              January 20 - December 21. <small>Aarhus, DK.</small>
            </h4>
            <ul className="responsibilities-list">
              <li>
                Undertaken the development, architecture, and testing of inhouse
                projects
              </li>
              <li>Provided consultancy services to clients</li>
              <li>
                Project estimation, communication, and guidance to ensure
                optimal solutions for clients
              </li>
            </ul>
            <ul className="tech-blocks">
              <li>Kubernetes</li>
              <li>Golang</li>
              <li>Helm</li>
              <li>Docker</li>
              <li>Ceph</li>
              <li>Flux</li>
              <li>ArgoCD</li>
              <li>Kafka</li>
              <li>Grafana</li>
              <li>Prometheus</li>
              <li>GitOps</li>
              <li>Terraform</li>
              <li>Ansible</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <h2>Software Developer - Nordic & Co</h2>
          </Accordion.Header>
          <Accordion.Body>
            <hr />
            <h4>
              June 18 - June 20. <small>Flåm, NO.</small>
            </h4>
            <ul className="responsibilities-list">
              <li>Developed back-end of website</li>
              <li>Connected CMS-system via REST-apis</li>
            </ul>
            <ul className="tech-blocks">
              <li>PHP</li>
              <li>Laravel</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <h2> IT Responsible - Flåm Guide Service</h2>
          </Accordion.Header>
          <Accordion.Body>
            <hr />
            <h4>
              April 15 - February 17. <small>Flåm, NO.</small>
            </h4>
            <ul className="responsibilities-list">
              <li>In charge of IT for a company of around 30 people</li>
              <li>Responsible for development and maintenance of website</li>
            </ul>
            <ul className="tech-blocks">
              <li>Java</li>
              <li>Wordpress</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </section>
  );
}
