import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import { HashLink as Link } from "react-router-hash-link";

import logo2 from "../images/logo2.svg";

import "../styles/Header.css";
import "../styles/Offcanvas.css";

export default function Header() {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop > lastScrollTop) {
        setIsHeaderVisible(false); // Scrolling down
      } else {
        setIsHeaderVisible(true); // Scrolling up
      }

      setLastScrollTop(scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  return (
    <header
      className={`header ${isHeaderVisible ? "show-box-shadow" : ""} ${
        window.scrollY === 0 ? "at-top" : ""
      } ${isHeaderVisible ? "" : "hidden"}`}
    >
      <>
        {["lg"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            collapseOnSelect="true"
            sticky="top"
          >
            <Navbar.Brand href="/" title="Home">
              <img src={logo2} alt="logo2"></img>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              className="offcanvas-toggle"
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              scroll="true"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <Navbar.Brand href="/" title="Logo">
                    <img src={logo2} alt="logo2"></img>
                  </Navbar.Brand>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav>
                  <Nav.Link eventKey="1" href="#home">
                    home
                  </Nav.Link>
                  <Nav.Link eventKey="2" href="#about">
                    about
                  </Nav.Link>
                  <Nav.Link eventKey="3" href="#experience">
                    experience
                  </Nav.Link>
                  <Nav.Link eventKey="4" href="#projects">
                    projects
                  </Nav.Link>
                  <Nav.Link eventKey="5" href="#certifications">
                    certifications
                  </Nav.Link>
                  <div className="offcanvas-button">
                    <Link
                      to="mailto:jesperbaxelsen@gmail.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className="contact-button">contact me</Button>
                    </Link>
                  </div>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Navbar>
        ))}
      </>
    </header>
  );
}
