import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faParagraph } from "@fortawesome/free-solid-svg-icons";
import "../styles/VerticalIconBar.css";

export default function VerticalIconBar() {
  return (
    <div className="vertical-icon-bar">
      <div className="icon-list">
        <ul>
          <li>
            <Link
              to="https://github.com/jaxels10"
              target="_blank"
              rel="noopener noreferrer"
              alt="GitHub icon"
              title="GitHub"
            >
              <FontAwesomeIcon icon={faGithub} className="icon" />
            </Link>
          </li>
          <li>
            <Link
              to="https://www.linkedin.com/in/jesperbergaxelsen/"
              target="_blank"
              rel="noopener noreferrer"
              alt="
              LinkedIn icon"
              title="LinkedIn"
            >
              <FontAwesomeIcon icon={faLinkedinIn} className="icon" />
            </Link>
          </li>

          <li>
            <Link
              to="https://dev.to/itminds/ceph-data-durability-redundancy-and-how-to-use-ceph-2ml0"
              target="_blank"
              rel="noopener noreferrer"
              alt="Pencil icon"
              title="Blog posts"
            >
              <FontAwesomeIcon icon={faParagraph} className="icon" />
            </Link>
          </li>
        </ul>
        <>
          <div className="vertical-line">
            <div className="vr"></div>
          </div>
        </>
      </div>
    </div>
  );
}
