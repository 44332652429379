import React from "react";
// import greenEnergyOutline from "../images/greenEnergyOutline.svg";

import "../styles/Projects.css";

export default function Projects() {
  return (
    <section className="projects" id="projects">
      <h3>projects</h3>
      <div className="projects-content">
        <h4>Energinet</h4>
        <hr />
        <p>
          I am working in a team on a project that will enable the necessary
          data gathering and processing needed to facilitate the move towards
          100% renewable energy. Moving towards 2030, we have a dire need to
          digitalize our energy sector, as well as collect more data to be able
          to make better decisions. This project is about building a platform
          that can be deployed onto both traditional cloud-environments such as
          Azure, but also onto bare metal. The platform has to be optimized in
          terms of IOPS, security and fault tolerance. It will become a vital
          piece of infrastructure in the Danish energy sector.
        </p>
      </div>
    </section>
  );
}
