import React from "react";
import Hero from "../components/Hero";
import Projects from "../components/Projects";
import Certifications from "../components/Certifications";
import Experience from "../components/Experience";
import About from "../components/About";

import "../styles/Content.css";

export default function Content() {
  return (
    <div className="content">
      <div className="main">
        <Hero />
        <About />
        <Experience />
        <Projects />
        <Certifications />
        {/*            
        <Test />
      */}
      </div>
    </div>
  );
}
