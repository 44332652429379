import React from "react";

import aboutme from "../images/aboutme.jpg";

import "../styles/About.css";

export default function About() {
  return (
    <section className="about" id="about">
      <h3>about</h3>
      <div className="about-content">
        <img src={aboutme} alt="Jesper Axelsen about me"></img>
        <div className="about-text">
          <hr />
          <p>
            I'm Jesper, a DevOps engineer with a passion for all things tech. I
            started my journey in the world of software engineering, graduating
            from Aalborg University, and eventually found my true calling in the
            exciting realm of DevOps.
          </p>
          <p>
            In my professional life, I pride myself on being open-minded,
            positive, and always ready to embrace new challenges. DevOps is all
            about bridging the gap between development and operations, and I
            thrive on finding innovative solutions to streamline and enhance the
            software development process.
          </p>
          <p>
            Whether it's automating deployments, optimizing infrastructure, or
            improving collaboration between teams, I'm always eager to take the
            initiative and push the envelope to ensure smooth and efficient
            operations.
          </p>
        </div>
      </div>
    </section>
  );
}
