import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faParagraph } from "@fortawesome/free-solid-svg-icons";

import "../styles/Footer.css";

export default function Footer() {
  return (
    <footer>
      <div className="footer-main">
        <h3>Say hello</h3>
        <Link
          to="mailto:jesperbaxelsen@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          jesperbaxelsen@gmail.com
        </Link>
        <div className="footer-icons">
          <Link
            to="https://github.com/jaxels10"
            target="_blank"
            rel="noopener noreferrer"
            alt="GitHub icon"
            title="GitHub"
          >
            <FontAwesomeIcon icon={faGithub} className="icon" />
          </Link>
          <Link
            to="https://www.linkedin.com/in/jesperbergaxelsen/"
            target="_blank"
            rel="noopener noreferrer"
            alt="
              LinkedIn icon"
            title="LinkedIn"
          >
            <FontAwesomeIcon icon={faLinkedinIn} className="icon" />
          </Link>
          <Link
            to="https://dev.to/itminds/ceph-data-durability-redundancy-and-how-to-use-ceph-2ml0"
            target="_blank"
            rel="noopener noreferrer"
            alt="Pencil icon"
            title="Blog posts"
          >
            <FontAwesomeIcon icon={faParagraph} className="icon" />
          </Link>
        </div>
      </div>
      <div className="footer-end">
        <p>Copyright 2023 - Axelsen consulting</p>
        <hr />
        <Nav>
          <Nav.Link href="#home">home</Nav.Link>
          <Nav.Link href="#about">about</Nav.Link>
          <Nav.Link href="#experience">experience</Nav.Link>
          <Nav.Link href="#projects">projects</Nav.Link>
          <Nav.Link href="#certifications">certifications</Nav.Link>
        </Nav>
      </div>
    </footer>
  );
}
