import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Header from "./components/Header";
import VerticalIconBar from "./components/VerticalIconBar";
import VerticalEmailBar from "./components/VerticalEmailBar";
import Content from "./components/Content";
import Footer from "./components/Footer";

import LoadingSpinner from "./components/LoadingSpinner";

import "./styles/App.css";

export default function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a time-consuming task (e.g. making API calls)
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <BrowserRouter>
          <div className="App">
            <Container fluid>
              <Header />
              <VerticalIconBar />
              <VerticalEmailBar />
              <Content />
              <Footer />
            </Container>
          </div>
        </BrowserRouter>
      )}
    </div>
  );
}
